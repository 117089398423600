// default login strings ?

import {SupportedPlatforms} from '../types/IConfig';
import {IPlatformVersion} from '../types/IHistoryVersion';

// default rating strings ?
export const algorithamConstants: {[key in supportedAlgProps]: number} = {
  supported_level_definition: 1,
  supported_questions_definition: 1000,
  supported_severity_definition: 1,
};

type supportedAlgProps =
  | 'supported_level_definition'
  | 'supported_questions_definition'
  | 'supported_severity_definition';

export const platformVersionHistory: {
  [value in SupportedPlatforms]: IPlatformVersion[];
} = {
  ios: [
    {
      version: '2.0.14',
      descriptions: ['Visual Improvements.', 'Bugfixes.'],
    },
    {
      version: '2.0.13',
      descriptions: ['Image upload size limit.', 'Bugfixes.'],
    },
    {
      version: '2.0.12',
      descriptions: [
        'Automatic unit values switch.',
        'More translations added.',
        'Bugfixes.',
      ],
    },
    {
      version: '2.0.11',
      descriptions: ['Version management fix.'],
    },
    {
      version: '2.0.10',
      descriptions: ['Small UI changes.', 'Bugfixes.'],
    },
    {
      version: '2.0.9',
      descriptions: [
        'Bugfix for ratings not parsed as numbers. Other bugfixes.',
      ],
    },
    {
      version: '2.0.8',
      descriptions: ['Redesign of Readiness Advisor for iOS!'],
    },
    {
      version: '2.0.0',
      descriptions: ['Inital release of the new Readiness Advisor for iOS!'],
    },
  ],
  android: [
    {
      version: '2.0.14',
      descriptions: ['Visual Improvements.', 'Bugfixes.'],
    },
    {
      version: '2.0.13',
      descriptions: ['Image upload size limit.', 'Bugfixes.'],
    },
    {
      version: '2.0.12',
      descriptions: [
        'Automatic unit values switch.',
        'More translations added.',
        'Bugfixes.',
      ],
    },
    {
      version: '2.0.11',
      descriptions: ['Version management fix.'],
    },
    {
      version: '2.0.10',
      descriptions: ['Small UI changes.', 'Bugfixes.'],
    },
    {
      version: '2.0.9',
      descriptions: [
        'Bugfix for ratings not parsed as numbers. Other bugfixes.',
      ],
    },
    {
      version: '2.0.8',
      descriptions: ['Redesign of Readiness Advisor for Android!'],
    },
    {
      version: '2.0.0',
      descriptions: [
        'Inital release of the new Readiness Advisor for Android!',
      ],
    },
  ],
  web: [
    {
      version: '2.0.14',
      descriptions: ['Visual Improvements.', 'Bugfixes.'],
    },
    {
      version: '2.0.13',
      descriptions: ['Image upload size limit.', 'Bugfixes.'],
    },
    {
      version: '2.0.12',
      descriptions: [
        'Automatic unit values switch.',
        'More translations added.',
        'Bugfixes.',
      ],
    },
    {
      version: '2.0.11',
      descriptions: ['Version management fix.'],
    },
    {
      version: '2.0.10',
      descriptions: ['Small UI changes.', 'Bugfixes.'],
    },
    {
      version: '2.0.9',
      descriptions: [
        'Bugfix for ratings not parsed as numbers. Other bugfixes.',
      ],
    },
    {
      version: '2.0.8',
      descriptions: ['Redesign of Readiness Advisor for Web!'],
    },
    {
      version: '2.0.0',
      descriptions: ['Inital release of the new Readiness Advisor for Web!'],
    },
  ],
};

export const platformVersionHistoryStaging: {
  [value in SupportedPlatforms]: IPlatformVersion[];
} = {
  ios: [
    {
      version: '2.0.14',
      descriptions: ['Visual Improvements.', 'Bugfixes.'],
    },
    {
      version: '2.0.13',
      descriptions: ['Image upload size limit.', 'Bugfixes.'],
    },
    {
      version: '2.0.12',
      descriptions: [
        'Automatic unit values switch.',
        'More translations added.',
        'Bugfixes.',
      ],
    },
    {
      version: '2.0.11',
      descriptions: ['Version management fix.'],
    },
    {
      version: '2.0.10',
      descriptions: ['Small UI changes.', 'Bugfixes.'],
    },
    {
      version: '2.0.9',
      descriptions: [
        'Bugfix for ratings not parsed as numbers. Other bugfixes.',
      ],
    },
    {
      version: '2.0.8',
      descriptions: ['Redesign of Readiness Advisor for iOS!'],
    },
    {
      version: '2.0.0',
      descriptions: ['Inital release of the new Readiness Advisor for iOS!'],
    },
  ],
  android: [
    {
      version: '2.0.14',
      descriptions: ['Visual Improvements.', 'Bugfixes.'],
    },
    {
      version: '2.0.13',
      descriptions: ['Image upload size limit.', 'Bugfixes.'],
    },
    {
      version: '2.0.12',
      descriptions: [
        'Automatic unit values switch.',
        'More translations added.',
        'Bugfixes.',
      ],
    },
    {
      version: '2.0.11',
      descriptions: ['Version management fix.'],
    },
    {
      version: '2.0.10',
      descriptions: ['Small UI changes.', 'Bugfixes.'],
    },
    {
      version: '2.0.9',
      descriptions: [
        'Bugfix for ratings not parsed as numbers. Other bugfixes.',
      ],
    },
    {
      version: '2.0.8',
      descriptions: ['Redesign of Readiness Advisor for Android!'],
    },
    {
      version: '2.0.0',
      descriptions: [
        'Inital release of the new Readiness Advisor for Android!',
      ],
    },
  ],
  web: [
    {
      version: '2.0.14',
      descriptions: ['Visual Improvements.', 'Bugfixes.'],
    },
    {
      version: '2.0.13',
      descriptions: ['Image upload size limit.', 'Bugfixes.'],
    },
    {
      version: '2.0.12',
      descriptions: [
        'Automatic unit values switch.',
        'More translations added.',
        'Bugfixes.',
      ],
    },
    {
      version: '2.0.11',
      descriptions: ['Version management fix.'],
    },
    {
      version: '2.0.10',
      descriptions: ['Small UI changes.', 'Bugfixes.'],
    },
    {
      version: '2.0.9',
      descriptions: [
        'Bugfix for ratings not parsed as numbers. Other bugfixes.',
      ],
    },
    {
      version: '2.0.8',
      descriptions: ['Redesign of Readiness Advisor for Web!'],
    },
    {
      version: '2.0.0',
      descriptions: ['Inital release of the new Readiness Advisor for Web!'],
    },
  ],
};

export const platformVersionHistoryDevelop: {
  [value in SupportedPlatforms]: IPlatformVersion[];
} = {
  ios: [
    {
      version: '2.0.14',
      descriptions: ['Visual Improvements.', 'Bugfixes.'],
    },
    {
      version: '2.0.13',
      descriptions: ['Image upload size limit.', 'Bugfixes.'],
    },
    {
      version: '2.0.12',
      descriptions: [
        'Automatic unit values switch.',
        'More translations added.',
        'Bugfixes.',
      ],
    },
    {
      version: '2.0.11',
      descriptions: ['Version management fix.'],
    },
    {
      version: '2.0.10',
      descriptions: ['Small UI changes.', 'Bugfixes.'],
    },
    {
      version: '2.0.9',
      descriptions: [
        'Bugfix for ratings not parsed as numbers. Other bugfixes.',
      ],
    },
    {
      version: '2.0.8',
      descriptions: ['Redesign of Readiness Advisor for iOS!'],
    },
    {
      version: '2.0.0',
      descriptions: ['Inital release of the new Readiness Advisor for iOS!'],
    },
  ],
  android: [
    {
      version: '2.0.14',
      descriptions: ['Visual Improvements.', 'Bugfixes.'],
    },
    {
      version: '2.0.13',
      descriptions: ['Image upload size limit.', 'Bugfixes.'],
    },
    {
      version: '2.0.12',
      descriptions: [
        'Automatic unit values switch.',
        'More translations added.',
        'Bugfixes.',
      ],
    },
    {
      version: '2.0.11',
      descriptions: ['Version management fix.'],
    },
    {
      version: '2.0.10',
      descriptions: ['Small UI changes.', 'Bugfixes.'],
    },
    {
      version: '2.0.9',
      descriptions: [
        'Bugfix for ratings not parsed as numbers. Other bugfixes.',
      ],
    },
    {
      version: '2.0.8',
      descriptions: ['Redesign of Readiness Advisor for Android!'],
    },
    {
      version: '2.0.0',
      descriptions: [
        'Inital release of the new Readiness Advisor for Android!',
      ],
    },
  ],
  web: [
    {
      version: '2.0.14',
      descriptions: ['Visual Improvements.', 'Bugfixes.'],
    },
    {
      version: '2.0.13',
      descriptions: ['Image upload size limit.', 'Bugfixes.'],
    },
    {
      version: '2.0.12',
      descriptions: [
        'Automatic unit values switch.',
        'More translations added.',
        'Bugfixes.',
      ],
    },
    {
      version: '2.0.11',
      descriptions: ['Version management fix.'],
    },
    {
      version: '2.0.10',
      descriptions: ['Small UI changes.', 'Bugfixes.'],
    },
    {
      version: '2.0.9',
      descriptions: [
        'Bugfix for ratings not parsed as numbers. Other bugfixes.',
      ],
    },
    {
      version: '2.0.8',
      descriptions: ['Redesign of Readiness Advisor for Web!'],
    },
    {
      version: '2.0.0',
      descriptions: ['Inital release of the new Readiness Advisor for Web!'],
    },
  ],
};

export const minYear = 2015;
